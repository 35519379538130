import {DATE_TIME_API_FORMAT} from '@/constants/timeFormats';
import {AppStartListening} from '@/redux/store/listenerMiddleware';
import spiService from '@/services/spiService';
import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

import {
    changeVendorReferralDateTime,
    changeVendorReferralDateTimeFail,
    changeVendorReferralDateTimeSuccess,
} from './slice';

const {NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

export default (startListening: AppStartListening) => {
    startListening({
        actionCreator: changeVendorReferralDateTime,
        effect: async (action, listenerApi) => {
            const {cb, actorId, field, value} = action.payload;

            if (cb) {
                cb({field, value});
            }

            try {
                const transformedValue = value
                    ? formatDateTime(value, DATE_TIME_API_FORMAT, NEW_DATE_TIME_FORMAT)
                    : null;
                field === 'referralSentDate'
                    ? await spiService.changeVendorReferralSentDate({actorId, value: transformedValue})
                    : await spiService.changeVendorReferralAcceptedDate({actorId, value: transformedValue});
                listenerApi.dispatch(changeVendorReferralDateTimeSuccess());
            } catch (err) {
                listenerApi.dispatch(changeVendorReferralDateTimeFail());
            }
        },
    });
};
