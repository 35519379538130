import {Check} from '@mui/icons-material';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled} from '@mui/material';

import {VIDEO_DEFAULT_CAMERA_ID, VIDEO_DEFAULT_MIC_ID, VIDEO_DEFAULT_SPEAKER_ID} from '@/constants/constants';

import {SettingsDevicesProps} from './Settings';
import {SelectOption} from './VideoCallWidget';

import styles from './videoCall.module.scss';

const CustomInputLabel = styled(InputLabel)({
    position: 'absolute',
    top: '-26px',
    left: '-4px',
    padding: '0 4px',
    zIndex: 1,
    transform: 'none',
    transition: 'none',
});

const CustomSelect = ({
    selectedValue,
    options,
    onChange,
    label,
}: {
    label: string;
    selectedValue?: SelectOption;
    options?: {
        value: string;
        label: string;
    }[];
    onChange: (event: SelectChangeEvent<string>) => void;
}) => {
    if (!options) {
        return null;
    }

    return (
        <FormControl fullWidth variant="outlined">
            <CustomInputLabel id="custom-select-label">{label}</CustomInputLabel>
            <Select
                labelId="custom-select-label"
                id="custom-select"
                value={selectedValue?.value || ''}
                onChange={onChange}
                sx={{
                    backgroundColor: 'white',
                    height: 40,
                    '& > #custom-select > svg': {
                        position: 'absolute',
                        top: 8,
                    },
                }}
                style={{display: 'flex'}}
            >
                {options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}{' '}
                        {selectedValue?.value === option.value && <Check style={{paddingLeft: 4}} fontSize="small" />}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const SettingsDevices = ({
    cameraList,
    micList,
    speakerList,
    curMic,
    curSpeaker,
    curCamera,
    setCurMic,
    setCurSpeaker,
    setCurCamera,
}: SettingsDevicesProps) => {
    const setCurrentMic = async (mic: any) => {
        const micValue = mic?.value;
        const curMicObj = window?.['microphone']?.find((microphone: any) => microphone.id === micValue);
        setCurMic(mic);
        localStorage.setItem(VIDEO_DEFAULT_MIC_ID, micValue);
        try {
            await window.vidyoConnector.SelectLocalMicrophone({
                localMicrophone: curMicObj,
            });
        } catch (error) {
            console.error('Error selecting microphone: ', error);
        }
    };

    const setCurrentCamera = async (cam: any) => {
        const camValue = cam?.value;
        const curCamObj = window?.['camera']?.find((camera: any) => camera.id === camValue);
        setCurCamera(cam);
        localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, camValue);
        try {
            await window.vidyoConnector.SelectLocalCamera({
                localCamera: curCamObj,
            });
        } catch (error) {
            console.error('Error selecting camera: ', error);
        }
    };

    const setCurrentSpeaker = async (speaker: any) => {
        const speakerValue = speaker?.value;
        const curSpeakerObj = window?.['speaker']?.find((spk: any) => spk.id === speakerValue);
        setCurSpeaker(speaker);
        localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, speakerValue);
        try {
            await window.vidyoConnector.SelectLocalSpeaker({
                localSpeaker: curSpeakerObj,
            });
        } catch (error) {
            console.error('Error selecting speaker: ', error);
        }
    };

    return (
        <>
            <div className={styles.settingsRow}>
                <CustomSelect
                    label="Microphone"
                    selectedValue={curMic}
                    options={micList}
                    onChange={(e) => {
                        const mic = micList.find((m) => m.value === e.target.value);
                        setCurrentMic(mic);
                    }}
                />
            </div>
            <div className={styles.settingsRow} style={{marginTop: 36}}>
                <CustomSelect
                    label="Camera"
                    selectedValue={curCamera}
                    options={cameraList}
                    onChange={(e) => {
                        const cam = cameraList.find((m) => m.value === e.target.value);
                        setCurrentCamera(cam);
                    }}
                />
            </div>
            <div className={styles.settingsRow} style={{marginTop: 36}}>
                <CustomSelect
                    label="Speaker"
                    selectedValue={curSpeaker}
                    options={speakerList}
                    onChange={(e) => {
                        const speaker = speakerList.find((m) => m.value === e.target.value);
                        setCurrentSpeaker(speaker);
                    }}
                />
            </div>
        </>
    );
};
