import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ReferralDocumentationState} from './stateTypes';

const initialState: ReferralDocumentationState = {
    loadingHandler: {sendReferralDocumentationLoading: false, changeVendorReferralDateTimeLoading: false},
};

const {actions, reducer} = createSlice({
    name: 'referralDocumentation',
    initialState,
    reducers: {
        sendReferralDocumentation: (state, _action: PayloadAction<{actorId: number; cb: () => void}>) => {
            state.loadingHandler.sendReferralDocumentationLoading = true;
        },
        sendReferralDocumentationSuccess: (state) => {
            state.loadingHandler.sendReferralDocumentationLoading = false;
        },
        sendReferralDocumentationFail: (state) => {
            state.loadingHandler.sendReferralDocumentationLoading = false;
        },
        changeVendorReferralDateTime: (
            state,
            _action: PayloadAction<{
                actorId: number;
                value: string | null;
                field: 'referralSentDate' | 'referralAcceptedDate';
                cb: ({
                    field,
                    value,
                }: {
                    field: 'referralSentDate' | 'referralAcceptedDate';
                    value: string | null;
                }) => void;
            }>
        ) => {
            state.loadingHandler.changeVendorReferralDateTimeLoading = true;
        },
        changeVendorReferralDateTimeSuccess: (state) => {
            state.loadingHandler.changeVendorReferralDateTimeLoading = false;
        },
        changeVendorReferralDateTimeFail: (state) => {
            state.loadingHandler.changeVendorReferralDateTimeLoading = false;
        },
    },
});

export const {
    sendReferralDocumentation,
    sendReferralDocumentationSuccess,
    sendReferralDocumentationFail,
    changeVendorReferralDateTime,
    changeVendorReferralDateTimeSuccess,
    changeVendorReferralDateTimeFail,
} = actions;

export default reducer;
