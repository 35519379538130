import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import classnames from 'classnames';

import {SirenIcon} from '@/assets/icons';
import {MHButton, MHLoader, MHTextButton} from '@/components/base';
import {LoggingActions} from '@/constants/loggingActions';
import {hideCallNotification} from '@/redux/commonCalls/slice';
import {ActiveCall} from '@/redux/commonCalls/types';
import {StoreStateShape} from '@/redux/store/storeStateTypes';
import {loggerService} from '@/services/loggerService';

import {AdditionalInfoBlock} from '../AddtionalInfoBlock';
import {getUserName} from '../shared/utils';

import styles from '../shared/common.module.scss';
import awsCallStyles from './incomingAWSHelpCall.module.scss';

export const IncomingAWSHelpCall = ({callData}: {callData: ActiveCall}) => {
    const dispatch = useDispatch();
    const [hideButtons, setHideButtons] = useState(false);
    const email = useSelector((store: StoreStateShape) => store.commonUserDataReducer?.user?.email);

    const {mountCb, unmountCb} = callData;
    const patientName = getUserName(callData);

    useEffect(() => {
        mountCb && mountCb();

        return () => {
            unmountCb && unmountCb();
        };
    }, [mountCb, unmountCb]);

    const callback = () => {
        setHideButtons(false);
    };

    const acceptCallHandler = () => {
        //TODO remove when awsConnectService will be moved to TS
        // @ts-ignore
        if (window.awsConnectService) {
            setHideButtons(true);
            //TODO remove when awsConnectService will be moved to TS
            // @ts-ignore
            window.awsConnectService.acceptContact(callback);
        }

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.amazonConnectOnAcceptButton,
            message: 'Accept Help Call button click',
        });
    };

    const dismissCallHandler = () => {
        setHideButtons(true);
        //TODO remove when awsConnectService will be moved to TS
        // @ts-ignore
        if (window.awsConnectService) window.awsConnectService.disconnectContact(callback);
        else callback();
        dispatch(hideCallNotification(callData?.meetingId?.toString()));

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.amazonConnectOnDeclineButton,
            message: 'Decline Help Call button click',
        });
    };

    return (
        <div className={classnames(styles.callWrapper, awsCallStyles.wrapper)}>
            <div className={styles.helpTopHeader}>
                This call was placed through the HELP button on the patient&apos;s PERS, or via their emergency
                wristband. The patient MAY NOT be in a position to respond.
            </div>

            <div className={awsCallStyles.headline}>Call From PERS HELP Button</div>

            <div className={classnames(styles.userInfo, awsCallStyles.awsCallUserInfo)}>
                <SirenIcon />
                <div className={classnames(styles.userPicture, awsCallStyles.helpCallUserPicture)}>
                    <PersonOutlineOutlinedIcon />
                </div>

                {!!patientName && (
                    <div data-dd-action-name="Patient Name" className={styles.userNameBlack}>
                        {patientName}
                    </div>
                )}
            </div>

            <AdditionalInfoBlock callData={callData} isBlackLabels />
            <div>
                {hideButtons ? (
                    <MHLoader />
                ) : (
                    <>
                        <MHButton text="Accept Call" buttonType="primarySecond" onClickHandler={acceptCallHandler} />
                        <MHTextButton text="Dismiss" btnType="primary-danger" onClick={dismissCallHandler} />
                    </>
                )}
            </div>
        </div>
    );
};
